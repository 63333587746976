import { useContext } from "react";
import axios from "axios";
import { userActions } from "../reducers/userReducer.js";
import { apiRoot } from "../services/api";
import { UserContext } from "../providers/UserProvider.js";

export const useUsers = () => {
  const { dispatch } = useContext(UserContext);

  const login = async (username, password) => {
    try {
      const res = await axios.post(apiRoot + "/token/", {
        username: username,
        password: password,
      });
      window.localStorage.setItem("afs_token", res.data);
      dispatch(userActions.setToken(res.data));
      dispatch(userActions.setUserError(null));
      getMe(res.data);
    } catch (err) {
      if (err.response.status === 401) {
        window.localStorage.removeItem("afs_token");
        window.localStorage.removeItem("afs_user");
        dispatch(userActions.setUserError("Incorrect email or password"));
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
      } else if (err.response.status === 403) {
        dispatch(
          userActions.setUserError(
            "You do not have the appropriate permissions to view / use this resource."
          )
        );
      } else {
        console.log(err);
        dispatch(
          userActions.setUserError("Sorry, an unhandled error occurred")
        );
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
        window.localStorage.removeItem("afs_token");
        window.localStorage.removeItem("afs_user");
      }
    }
  };

  const logout = (props) => {
    props.history.replace("/login");
    dispatch(userActions.setToken(null));
    dispatch(userActions.setMe(null));
    window.localStorage.removeItem("afs_token");
    window.localStorage.removeItem("afs_user");
  };

  const getMe = async (token) => {
    if (!token) {
      token = JSON.parse(window.localStorage.getItem("afs_token"));
    }
    if (token) {
      try {
        const res = await axios.get(apiRoot + "/users/me/", {
          headers: {
            Authorization: `Bearer ${token.access}`,
          },
        });
        window.localStorage.setItem("afs_user", res.data);
        dispatch(userActions.setMe(res.data));
        dispatch(userActions.setUserError(null));
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setUserError("Unauthorized"));
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
        } else if (err.response.status === 403) {
          dispatch(
            userActions.setUserError(
              "You do not have the appropriate permissions to view / use this resource."
            )
          );
        } else {
          console.log(err);
          dispatch(
            userActions.setUserError("Sorry, an unhandled error occurred")
          );
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
        }
      }
    }
  };

  return {
    login,
    logout,
    getMe,
  };
};
