import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { StationMap } from "../Mapping/StationMap.jsx";
import { StationDetail } from "./StationDetail";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  forecastBar: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: `calc(100vh - 80px)`,
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    justify: "right",
  },
  grow: {
    flexGrow: 1,
  },
  forecastApproval: {
    marginLeft: "auto",
  },
  forecastDetail: {
    marginLeft: "auto",
  },
  typography: {
    padding: theme.spacing(2),
  },
  popover: {
    padding: theme.spacing(2),
  },
}));

export const Stations = () => {
  const classes = useStyles();
  const [selectedFeature, setSelectedFeature] = useState(null);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={5}>
          <Paper className={classes.paper}>
            <StationMap
              setSelectedFeature={setSelectedFeature}
              selectedFeature={selectedFeature}
            />
          </Paper>
        </Grid>
        <Grid item sm={12} md={7}>
          <Paper className={classes.paper}>
            {!selectedFeature ? (
              "Select a station to see data"
            ) : (
              <StationDetail feature={selectedFeature} />
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
