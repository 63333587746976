import React, { useState, useEffect, useRef, useContext } from "react";
import "leaflet/dist/leaflet.css";
import "../../css/Map.css";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { MapContainer, TileLayer } from "react-leaflet";
import { apiRoot } from "../../services/api";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { StationGeoJSON } from "./StationGeoJSON";
import { SubbasinGeoJSON } from "./SubbasinGeoJSON";
import { BasinBoundaryGeoJSON } from "./BasinBoundaryGeoJSON";

export const StationMap = ({ setSelectedFeature, selectedFeature }) => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;

  const [hasError, setErrors] = useState(false);
  const [stations, setStations] = useState({});
  const [basins, setBasins] = useState({});
  const [boundaries, setBoundaries] = useState({});

  const lat = 20;
  const lng = 77;
  const zoom = 5;
  const position = [lat, lng];

  useEffect(() => {
    const fetchStations = async () => {
      try {
        const res = await axios.get(apiRoot + "/locations/", {
          params: {
            layer__code: "STATIONS",
          },
          headers: {
            Authorization: `Bearer ${token.access}`,
          },
        });
        setStations(res.data);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
        } else {
          console.log(err);
          setErrors(err.message);
        }
      }
    };

    fetchStations();
  }, [dispatch, token]);

  useEffect(() => {
    const fetchSubbasins = async () => {
      try {
        const res = await axios.get(apiRoot + "/locations/", {
          params: {
            layer__code: "SUBBASINS",
          },
          headers: {
            Authorization: `Bearer ${token.access}`,
          },
        });
        setBasins(res.data);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
        } else {
          console.log(err);
          setErrors(err.message);
        }
      }
    };

    fetchSubbasins();
  }, [dispatch, token]);

  useEffect(() => {
    const fetchBasinBoundaries = async () => {
      try {
        const res = await axios.get(apiRoot + "/locations/", {
          params: {
            layer__code: "BASINS",
          },
          headers: {
            Authorization: `Bearer ${token.access}`,
          },
        });
        console.log("boundaries:", res.data);
        setBoundaries(res.data);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
        } else {
          console.log(err);
          setErrors(err.message);
        }
      }
    };

    fetchBasinBoundaries();
  }, [dispatch, token]);

  const basinRef = useRef();
  const stationsPointsRef = useRef();
  const boundariesRef = useRef();

  useEffect(() => {
    const reorderLayers = () => {
      if (stationsPointsRef.current) {
        boundariesRef.current.bringToFront();
        basinRef.current.bringToFront();
        stationsPointsRef.current.bringToFront();
      }
    };
    reorderLayers();
  }, [basins]);

  return !hasError ? (
    <div className="home-map">
      <MapContainer center={position} zoom={zoom}>
        <TileLayer
          attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community"
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
        />

        <StationGeoJSON
          geoJsonRef={stationsPointsRef}
          features={stations.features}
          setSelectedFeature={setSelectedFeature}
        />
        <SubbasinGeoJSON
          geoJsonRef={basinRef}
          features={basins.features}
          setSelectedFeature={setSelectedFeature}
          selectedFeature={selectedFeature}
          selectable={true}
        />
        <BasinBoundaryGeoJSON
          geoJsonRef={boundariesRef}
          features={boundaries.features}
          setSelectedFeature={setSelectedFeature}
          selectedFeature={selectedFeature}
          selectable={true}
        />
      </MapContainer>
    </div>
  ) : (
    <Typography>Error: {JSON.stringify(hasError)}</Typography>
  );
};
