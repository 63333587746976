import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import decode from "jwt-decode";
import { UserContext } from "../../providers/UserProvider";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(UserContext);
  const { token } = state.user;

  const isAuthenticated = () => {
    return token && !isTokenExpired(token.access);
  };

  const isTokenExpired = () => {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
