import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import { DataTreeView } from "./DataTreeView";
import { apiRoot } from "../../services/api";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";

export const Tree = ({
  selectedNodeId,
  setSelectedNodeId,
  expanded,
  setExpanded,
}) => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;
  const [hasError, setErrors] = useState(false);
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    const fetchTreeData = async () => {
      try {
        const res = await axios.get(apiRoot + "/tree_nodes/tree/", {
          params: {
            format: "json",
            enabled: true,
            level: 0
          },
          headers: {
            Authorization: `Bearer ${token.access}`,
          },
        });
        setTreeData(res.data);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
        } else {
          console.log(err);
          setErrors(err.message);
        }
      }
    };
    fetchTreeData();
  }, [dispatch, token]);

  return (
    <DataTreeView
      treeItems={treeData}
      selectedNodeId={selectedNodeId}
      setSelectedNodeId={setSelectedNodeId}
      expanded={expanded}
      setExpanded={setExpanded}
      hasError={hasError}
    />
  );
};
