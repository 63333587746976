import "leaflet/dist/leaflet.css";
import "../../css/Map.css";
import React from "react";
import { GeoJSON, useMapEvents } from "react-leaflet";
import { makeStyles } from "@material-ui/core/styles";
import L from "leaflet";

import f from "../../images/icons/streamflow.png";
import sel_f from "../../images/icons/selected_streamflow.png";
import l from "../../images/icons/reservoir.png";
import sel_l from "../../images/icons/selected_reservoir.png";
import w from "../../images/icons/weather.png";
import sel_w from "../../images/icons/selected_weather.png";
import fl from "../../images/icons/streamflow_reservoir.png";
import sel_fl from "../../images/icons/selected_streamflow_reservoir.png";
import fw from "../../images/icons/streamflow_weather.png";
import sel_fw from "../../images/icons/selected_streamflow_weather.png";
import lw from "../../images/icons/reservoir_weather.png";
import sel_lw from "../../images/icons/selected_reservoir_weather.png";
import flw from "../../images/icons/streamflow_reservoir_weather.png";
import sel_flw from "../../images/icons/selected_streamflow_reservoir_weather.png";
import s from "../../images/icons/snow.png";
import sel_s from "../../images/icons/selected_snow.png";

const useStyles = makeStyles((theme) => ({
  toolTip: {
    fontSize: 11,
    color: "black",
    backgroundColor: "rgba(52, 52, 52, 0)",
    borderWidth: "0px",
  },
}));

export const StationGeoJSON = ({
  features,
  setSelectedFeature,
  geoJsonRef,
}) => {
  const classes = useStyles();
  const iconSize = [16, 16];
  const icons = {
    F_L_W: new L.Icon({
      iconUrl: flw,
      iconSize: iconSize,
    }),
    F_L: new L.Icon({
      iconUrl: fl,
      iconSize: iconSize,
    }),
    L_W: new L.Icon({
      iconUrl: lw,
      iconSize: iconSize,
    }),
    F_W: new L.Icon({
      iconUrl: fw,
      iconSize: iconSize,
    }),
    F: new L.Icon({
      iconUrl: f,
      iconSize: iconSize,
    }),
    L: new L.Icon({
      iconUrl: l,
      iconSize: iconSize,
    }),
    W: new L.Icon({
      iconUrl: w,
      iconSize: iconSize,
    }),
    S: new L.Icon({
      iconUrl: s,
      iconSize: iconSize,
    }),
    SEL_F_L_W: new L.Icon({
      iconUrl: sel_flw,
      iconSize: iconSize,
    }),
    SEL_F_L: new L.Icon({
      iconUrl: sel_fl,
      iconSize: iconSize,
    }),
    SEL_L_W: new L.Icon({
      iconUrl: sel_lw,
      iconSize: iconSize,
    }),
    SEL_F_W: new L.Icon({
      iconUrl: sel_fw,
      iconSize: iconSize,
    }),
    SEL_F: new L.Icon({
      iconUrl: sel_f,
      iconSize: iconSize,
    }),
    SEL_L: new L.Icon({
      iconUrl: sel_l,
      iconSize: iconSize,
    }),
    SEL_W: new L.Icon({
      iconUrl: sel_w,
      iconSize: iconSize,
    }),
    SEL_S: new L.Icon({
      iconUrl: sel_s,
      iconSize: iconSize,
    }),
  };
  const stationIcon = (feature) => {
    try {
      const symbol_name = feature.properties.fields.find(
        (field) => field.attribute_name.code === "SYMBOLOGY"
      ).char_value;
      // return icons["F_L_W"]
      if (symbol_name.indexOf("F_L_W") > -1) {
        return icons["F_L_W"];
      } else if (symbol_name.indexOf("F_L") > -1) {
        return icons["F_L"];
      } else if (symbol_name.indexOf("L_W") > -1) {
        return icons["L_W"];
      } else if (symbol_name.indexOf("F") > -1) {
        if (symbol_name.indexOf("F") !== symbol_name.indexOf("FC")) {
          return icons["F"];
        } else {
          return icons["F_L_W"];
        }
      } else if (symbol_name.indexOf("L") > -1) {
        return icons["L"];
      } else if (symbol_name.indexOf("W") > -1) {
        return icons["W"];
      } else if (symbol_name.indexOf("S") > -1) {
        return icons["S"];
      } else {
        return icons["F_L_W"];
      }
    } catch (err) {
      return icons["F_L_W"];
    }
  };
  const selectedStationIcon = (feature) => {
    try {
      const symbol_name = feature.properties.fields.find(
        (field) => field.attribute_name.code === "SYMBOLOGY"
      ).char_value;
      if (symbol_name.indexOf("F_L_W") > -1) {
        return icons["SEL_F_L_W"];
      } else if (symbol_name.indexOf("F_L") > -1) {
        return icons["SEL_F_L"];
      } else if (symbol_name.indexOf("L_W") > -1) {
        return icons["SEL_L_W"];
      } else if (symbol_name.indexOf("F") > -1) {
        if (symbol_name.indexOf("F") !== symbol_name.indexOf("FC")) {
          return icons["SEL_F"];
        } else {
          return icons["SEL_F_L_W"];
        }
      } else if (symbol_name.indexOf("L") > -1) {
        return icons["SEL_L"];
      } else if (symbol_name.indexOf("W") > -1) {
        return icons["SEL_W"];
      } else if (symbol_name.indexOf("S") > -1) {
        return icons["SEL_S"];
      } else {
        return icons["SEL_F_L_W"];
      }
    } catch (err) {
      return icons["SEL_F_L_W"];
    }
  };
  const onEachFeature = (feature, layer) => {
    layer.on({
      click: () => {
        // console.log("clicked: " + feature.properties.name + " - " + feature.id)
        geoJsonRef.current.getLayers().forEach((layer2) => {
          layer2
            .getLayers()
            .forEach((marker) => marker.setIcon(stationIcon(layer2.feature)));
        });
        layer.getLayers().forEach((marker) => {
          marker.setIcon(selectedStationIcon(feature));
        });
        setSelectedFeature(feature);
      },
      mouseover: () => {
        layer.bindTooltip(
          feature.properties.name + " (" + feature.properties.code + ")",
          { direction: "right" }
        );
        layer.openTooltip();
      },
      mouseout: () => {
        layer.closeTooltip();
      },
    });
  };
  const mapEvents = useMapEvents({
    click: () => {
      geoJsonRef.current.getLayers().forEach((layer2) => {
        layer2
          .getLayers()
          .forEach((marker) => marker.setIcon(stationIcon(layer2.feature)));
      });
    },
    zoomend: () => {
      if (mapEvents.getZoom() >= 11) {
        geoJsonRef.current.getLayers().forEach((layer) => {
          layer.getLayers().forEach((marker) => {
            marker.unbindTooltip();
            marker.bindTooltip(layer.feature.properties.code, {
              direction: "right",
              permanent: true,
              opacity: 1,
              className: classes.toolTip,
            });
            marker.openTooltip();
          });
        });
      } else {
        geoJsonRef.current.getLayers().forEach((layer) => {
          layer.getLayers().forEach((marker) => {
            marker.unbindTooltip();
          });
        });
      }
    },
  });

  return (
    <GeoJSON
      ref={geoJsonRef}
      key={features}
      data={features}
      onEachFeature={onEachFeature}
      pointToLayer={(feat, latlng) => {
        return L.marker(latlng, { icon: stationIcon(feat) });
      }}
    />
  );
};
