import { DateTime } from "luxon";

export const getRoundedDate = (minutes, d) => {
  d = new Date(d);
  let ms = 1000 * 60 * minutes; // convert minutes to ms
  let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);
  return roundedDate;
};

export const dateFormatter = (date) => {
  const options = { hour12: false };
  const d = new Date(date);
  return `${d.toLocaleDateString()}  ${d
    .toLocaleTimeString("en-US", options)
    .slice(0, 5)}`;
};

export const dateFormatterTEST = (date) => {
  const options = { hour12: false };
  const d = new Date(date);
  return `${d.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  })} ${d.toLocaleTimeString("en-US", options).slice(0, 5)}`;
};

export const roundValue = (value) => {
  return parseFloat(value).toFixed(1);
};

export const formatForecastDateTime = (datetime) => {
  let dt = DateTime.fromISO(datetime);
  return dt.toFormat("y-LL-dd HH:mm ZZZZ");
};

export const formatAlertDateTime = (datetime) => {
  let dt = DateTime.fromISO(datetime);
  return dt.toFormat("LL-dd-y HH:mm");
};

export const filterManualEntryStations = (list) => {
  const filteredList = [];
  list.forEach((station) => {
    const manualEntryIndex = station.properties.fields.findIndex((field) => {
      return field.attribute_name.code === "MANUAL_ENTRY";
    });

    if (station.properties.fields[manualEntryIndex].bool_value === true) {
      filteredList.push(station);
    }
  });

  return filteredList;
};

export const findExistingAlerts = (array) => {
  let alertFlag = "noalert";

  array.forEach((scenario) => {
    if (scenario.latest_child !== null) {
      return scenario.latest_child.alerts.length > 0
        ? (alertFlag = "alert")
        : null;
    }
  });

  return alertFlag;
};

export const alertLogicTranslator = (logic) => {
  let text;
  switch (logic) {
    case "GT":
      text = "Greater than";
      break;
    case "GTE":
      text = "Greater than or equal to";
      break;
    case "LTE":
      text = "Less than or equal to";
      break;
    case "LT":
      text = "Less than";
      break;
    default:
      text = "alert logic";
  }

  return text;
};
