import { makeStyles } from "@material-ui/core";

export const ForecastStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  forecastBar: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  forecastToggleBar: {
    display: "flex",
    justifyContent: "center",
  },
  modelStatesBar: {
    marginBottom: theme.spacing(5),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: `calc(100vh - 160px)`,
    overflow: `auto`,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    justify: "right",
  },
  grow: {
    flexGrow: 1,
  },
  forecastApproval: {
    marginLeft: "auto",
  },
  forecastDetail: {
    marginLeft: "auto",
  },
  typography: {
    padding: theme.spacing(2),
  },
  popover: {
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1),
  },
  tabPanelList: {
    padding: "0px",
    overflow: "auto",
  },
}));
