import React from "react";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LoopIcon from "@material-ui/icons/Loop";
import ForwardIcon from "@material-ui/icons/Forward";
import TimelineIcon from "@material-ui/icons/Timeline";
import TreeItem from "@material-ui/lab/TreeItem";

export const selectIcon = (treeItem) => {
  if (treeItem.tree_node_type) {
    if (treeItem.tree_node_type.name === "Scenario") {
      //Scenario
      return LoopIcon;
    } else if (treeItem.tree_node_type.name === "Forecast") {
      //Forecast
      return ForwardIcon;
    } else if (treeItem.tree_node_type.name === "Source") {
      //Source
      return FolderIcon;
    } else {
      return LocationOnIcon;
    }
  } else {
    return TimelineIcon;
  }
};

export const parseDateTime = (nodeName) => {
  let dt = DateTime.fromISO(nodeName);
  if (dt.isValid) {
    return dt.toFormat("y-LL-dd HH:mm ZZZZ");
  } else {
    return nodeName;
  }
};

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

export const StyledTreeItem = ({
  labelText,
  labelIcon: LabelIcon,
  labelInfo,
  color,
  bgColor,
  ...other
}) => {
  const classes = useTreeItemStyles();

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
};

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  tsId: PropTypes.string,
};
