import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Paper,
  AppBar,
  FormControl,
  NativeSelect,
  Switch,
  FormControlLabel,
  Toolbar,
  LinearProgress,
  Divider,
  Typography,
  Collapse,
} from "@material-ui/core";
import axios from "axios";
import { apiRoot } from "../../services/api";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { ForecastPlot } from "./ForecastPlot";
import { ForecastMap } from "./ForecastMap";
import { ForecastStyles } from "./ForecastStyles";
import { NodeSelector } from "../Utils/NodeSelector";
//import { ModelStatesSettings } from "../ModelStates/ModelStatesSettings";
import { formatForecastDateTime } from "../../helpers/helpers";
//import { ModelStatesContainer } from "../ModelStates/ModelStatesContainer";
import { StationMapListTabs } from "../Utils/StationMapListTabs";
import { ForecastStationList } from "./ForecastStationList";

export const Forecasts = () => {
  const { dispatch, state } = useContext(UserContext);
  const { token } = state.user;

  const [hasError, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forecastSourceNode] = useState({
    "id": "7221e7c9-bcf1-4038-873d-7fd248b21b06",
    "name": "EHPFS",
    "parent": null,
    "enabled": true,
    "tree_node_type": {
        "id": "720c7022-8fe9-4d2b-94b8-9d1a14b443c6",
        "name": "Directory",
        "code": "DIR",
        "description": "Tree Node for general organization of timeseries",
        "enabled": true
    },
    "level": 0,
    "forecast": null,
    "source": {
        "id": "ae6afd58-b361-40ad-bde6-592b67ac950b",
        "name": "Extended Hydrologic Prediction - Forecast System",
        "code": "EHPFS",
        "enabled": true,
        "description": null,
        "source_type": "4fd52abd-fdff-4a57-97de-cde1e9869722",
        "tree_node": "7221e7c9-bcf1-4038-873d-7fd248b21b06"
    },
    "scenario": null,
    "properties": null
    });

  const classes = ForecastStyles();
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedBasinNode, setSelectedBasinNode] = useState(null);
  const [selectedScenarioNode, setSelectedScenarioNode] = useState(null);
  const [selectedForecastNode, setSelectedForecastNode] = useState(null);
  const [forecastNodes, setForecastNodes] = useState([]);
  const [forecastApproved, setForecastApproved] = useState(false);
  const [modelStatesCheck, setModelStatesCheck] = useState(null);
  const [mapCheck, setMapCheck] = useState(true);
  const [modelStatesTS, setModelStatesTS] = useState([]);

  const handleChange = (event) => {
    setSelectedForecastNode(
      forecastNodes.find((node) => node.id === event.target.value)
    );
  };

  useEffect(() => {
    const fetchLatestForecast = async () => {
      try {
        if (selectedScenarioNode) {
          setLoading(true);
          const res = await axios.get(
            apiRoot + "/tree_nodes/" + selectedScenarioNode.id + "/children/",
            {
              params: {
                forecast__status__code: "SUCCESS",
              },
              headers: {
                Authorization: `Bearer ${token.access}`,
              },
            }
          );
          const { data } = res;
          if (data.length > 0) {
            setSelectedForecastNode(data[data.length - 1]);
            setForecastApproved(data[data.length - 1].forecast.approved);
            setForecastNodes(data.reverse());
            setLoading(false);
          }
        }
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setErrors(err.message);
          setLoading(false);
        }
      }
    };

    fetchLatestForecast();
  }, [selectedScenarioNode, dispatch, token]);

  useEffect(() => {
    if (selectedForecastNode) {
      setForecastApproved(selectedForecastNode.forecast.approved);
    }
  }, [selectedForecastNode]);

  useEffect(() => {
    setSelectedFeature(null);
  }, [selectedBasinNode]);

  useEffect(() => {
    const fetchModelStatesTS = async () => {
      setLoading(true);
      try {
        const res = await axios.get(apiRoot + `/timeseries/`, {
          params: {
            location__code: selectedFeature.properties.code,
            tree_node: selectedScenarioNode.id,
            parameter__code: "SNWE,UZTWC,UZFWC,LZFPC,LZFSC,LZTWC",
            format: "json",
            ordering: "-order",
          },
          headers: {
            Authorization: `Bearer ${state.user.token.access}`,
          },
        });
        const { data } = res;
        setModelStatesTS(data);
        setLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setErrors(err.message);
          setLoading(false);
        }
      }
    };

    selectedFeature && selectedScenarioNode && fetchModelStatesTS();
  }, [
    selectedFeature,
    selectedScenarioNode,
    dispatch,
    state.user.token.access,
  ]);

  const handleForecastApproval = async () => {
    try {
      const res = await axios.patch(
        apiRoot + `/forecasts/${selectedForecastNode.forecast.id}/`,
        {
          approved: !selectedForecastNode.forecast.approved,
        },
        {
          headers: {
            Authorization: `Bearer ${token.access}`,
          },
        }
      );
      let { data } = res;
      console.log(data);
      setSelectedForecastNode({
        ...selectedForecastNode,
        forecast: data,
      });
      setForecastNodes(
        forecastNodes.map((node) => {
          if (node.forecast.id === data.id) {
            return { ...node, forecast: data };
          } else {
            return node;
          }
        })
      );
    } catch (err) {
      if (err.response.status === 401) {
        window.localStorage.removeItem("afs_token");
        window.localStorage.removeItem("afs_user");
        dispatch(userActions.setToken(null));
        dispatch(userActions.setMe(null));
        setErrors("Unauthorized");
      } else if (err.response.status === 403) {
        setErrors(
          "You do not have the appropriate permissions to view / use this resource."
        );
      } else {
        console.log(err);
        setErrors(err.message);
      }
    }
  };

  const ApprovalButton = () => {
    return !hasError ? (
      <FormControlLabel
        control={
          <Switch
            checked={forecastApproved}
            onChange={handleForecastApproval}
            name="forecast-approved"
            color="primary"
          />
        }
        label="Forecast Approved"
      />
    ) : (
      <Typography>Error: {JSON.stringify(hasError)}</Typography>
    );
  };

  const SelectForecast = () => {
    return (
      <>
        {!loading ? (
          !hasError ? (
            <FormControl className={classes.formControl}>
              <NativeSelect
                value={selectedForecastNode ? selectedForecastNode.id : ""}
                onChange={handleChange}
                inputProps={{
                  name: "forecast",
                  id: "forecast-select",
                }}
              >
                <option aria-label="None" value="" />
                {forecastNodes.map((value, index) => {
                  return (
                    <option key={index} value={value.id}>
                      {formatForecastDateTime(value.forecast.valid_at)}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          ) : (
            <Typography>Error: {JSON.stringify(hasError)}</Typography>
          )
        ) : (
          <LinearProgress />
        )}
      </>
    );
  };

  const handleModelStatesCheck = () => {
    setModelStatesCheck(modelStatesCheck ? false : true);
  };

  const handleMapCheck = () => {
    setMapCheck(mapCheck ? false : true);
  };

  const mapComponent = (
    <div role="img" aria-label="Sub-basin map">
      <ForecastMap
        className="forecast-map"
        setSelectedFeature={setSelectedFeature}
        selectedBasinNode={selectedBasinNode}
        selectedFeature={selectedFeature}
      />
    </div>
  );

  const listComponent = (
    <ForecastStationList
      handleClick={setSelectedFeature}
      selectedBasinNode={selectedBasinNode}
    />
  );

  return (
    <div className={classes.root}>
      <AppBar className={classes.forecastBar} position="static" color="default">
        <Toolbar>
          <Typography>Basin: </Typography>
          {forecastSourceNode && (
            <NodeSelector
              selectedNode={selectedBasinNode}
              setSelectedNode={setSelectedBasinNode}
              url={
                apiRoot + "/tree_nodes/" + forecastSourceNode.id + "/children/"
              }
              params={{}}
              defaultSelected={0}
            />
          )}
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className={classes.divider}
          />
          <Typography>Scenario: </Typography>
          {selectedBasinNode && (
            <NodeSelector
              selectedNode={selectedScenarioNode}
              setSelectedNode={setSelectedScenarioNode}
              url={
                apiRoot + "/tree_nodes/" + selectedBasinNode.id + "/children/"
              }
              params={{}}
              defaultSelected={0}
            />
          )}

          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className={classes.divider}
          />
          <Typography>Forecast: </Typography>
          {selectedScenarioNode && <SelectForecast />}
          <div className={classes.grow} />
          <div className={classes.forecastApproval}>
            {selectedForecastNode && <ApprovalButton />}
          </div>
          {/*<div className={classes.forecastDetail}>
            <ModelStatesSettings
              handleMapCheck={handleMapCheck}
              handleModelStatesCheck={handleModelStatesCheck}
              mapCheck={mapCheck}
              modelStatesCheck={modelStatesCheck}
            />
          </div>*/}
        </Toolbar>
      </AppBar>

      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={
            (!mapCheck && 0) ||
            (mapCheck && modelStatesCheck && 3) ||
            (mapCheck && !modelStatesCheck && 3)
          }
        >
          <Collapse in={mapCheck}>
            <Paper className={classes.paper}>
              <StationMapListTabs
                mapComponent={mapComponent}
                listComponent={listComponent}
              />
            </Paper>
          </Collapse>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={
            (!mapCheck && !modelStatesCheck && 12) ||
            (!mapCheck && modelStatesCheck && 6) ||
            (mapCheck && modelStatesCheck && 4) ||
            (mapCheck && !modelStatesCheck && 9)
          }
        >
          <Paper className={classes.paper}>
            {!selectedFeature || !selectedForecastNode ? (
              "Select basin to see plot"
            ) : (
              <ForecastPlot
                feature={selectedFeature}
                forecast={selectedForecastNode.forecast}
                scenario={selectedScenarioNode.scenario}
              />
            )}
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={
            (!modelStatesCheck && 0) ||
            (!mapCheck && modelStatesCheck && 6) ||
            (mapCheck && modelStatesCheck && 5)
          }
        >
          <Collapse in={modelStatesCheck}>
            {/*<Paper className={classes.paper}>
              {!selectedFeature || !selectedForecastNode ? (
                "Select basin to see plot"
              ) : (
                <ModelStatesContainer
                  timeseriesList={modelStatesTS}
                  feature={selectedFeature}
                />
              )}
            </Paper>*/}
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
};
