const SET_ME = "SET_ME";
const SET_TOKEN = "SET_TOKEN";
const SET_USER_ERROR = "SET_USER_ERROR";

export const userActions = {
  setUserError(error) {
    return {
      type: SET_USER_ERROR,
      error: error,
    };
  },
  setMe(me) {
    return {
      type: SET_ME,
      me: me,
    };
  },
  setToken(token) {
    return {
      type: SET_TOKEN,
      token: token,
    };
  },
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case SET_TOKEN:
      window.localStorage.setItem("afs_token", JSON.stringify(action.token));
      return {
        ...state,
        token: action.token,
      };
    case SET_ME:
      window.localStorage.setItem("afs_user", JSON.stringify(action.me));
      return {
        ...state,
        me: action.me,
      };
    case SET_USER_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export const userInitialState = {
  token: JSON.parse(window.localStorage.getItem("afs_token")),
  me: JSON.parse(window.localStorage.getItem("afs_user")),
  error: null,
};
