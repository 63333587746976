import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import axios from "axios";
import { DateTime } from "luxon";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { apiRoot } from "../../services/api";

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: "100%",
  },
}));

export const TimeseriesTable = ({ meta, data, setData }) => {
  const classes = useStyles();

  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;

  const getRoundedDate = (minutes, d) => {
    d = new Date(d);
    let ms = 1000 * 60 * minutes; // convert minutes to ms
    let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);
    return roundedDate;
  };

  return (
    <div className={classes.table}>
      <MaterialTable
        columns={[
          {
            title: "Datetime (" + DateTime.now().toFormat("ZZZZ") + ")",
            field: "datetime",
            type: "datetime",
          },
          { title: "Value", field: "num_value" },
        ]}
        data={data}
        title={null}
        options={{
          exportButton: true,
          exportAllData: true,
          exportFileName: meta.name,
        }}
        editable={{
          onRowAddCancelled: (rowData) => console.log("Row adding cancelled"),
          onRowUpdateCancelled: (rowData) =>
            console.log("Row editing cancelled"),
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                axios
                  .post(
                    `${apiRoot}/timeseries_values/`,
                    {
                      timeseries: meta.id,
                      datetime: getRoundedDate(1, newData.datetime),
                      num_value: newData.num_value,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token.access}`,
                      },
                    }
                  )
                  .then(function (res) {
                    // console.log(newData)
                    // console.log(res.data)
                    setData([...data, res.data]);
                    resolve();
                  })
                  .catch(function (err) {
                    if (err.response.status === 401) {
                      window.localStorage.removeItem("afs_token");
                      window.localStorage.removeItem("afs_user");
                      dispatch(userActions.setToken(null));
                      dispatch(userActions.setMe(null));
                      console.log(err.message);
                      reject();
                    } else if (err.response.status >= 400) {
                      console.log(err.message);
                      reject();
                    } else {
                      console.log(err.message);
                      reject();
                    }
                  });
              }, 1000);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                axios
                  .patch(
                    `${apiRoot}/timeseries_values/${newData.id}/`,
                    {
                      datetime: getRoundedDate(1, newData.datetime),
                      num_value: newData.num_value,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token.access}`,
                      },
                    }
                  )
                  .then(function (res) {
                    setData([...dataUpdate]);
                    resolve();
                  })
                  .catch(function (err) {
                    if (err.response.status === 401) {
                      window.localStorage.removeItem("afs_token");
                      window.localStorage.removeItem("afs_user");
                      dispatch(userActions.setToken(null));
                      dispatch(userActions.setMe(null));
                      console.log(err.message);
                      reject();
                    } else if (err.response.status >= 400) {
                      console.log(err.message);
                      reject();
                    } else {
                      console.log(err.message);
                    }
                  });
              }, 1000);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                const deleteId = dataDelete[index].id;

                axios
                  .delete(`${apiRoot}/timeseries_values/${deleteId}/`, {
                    headers: {
                      Authorization: `Bearer ${token.access}`,
                    },
                  })
                  .then(function (res) {
                    dataDelete.splice(index, 1);
                    setData([...dataDelete]);
                    resolve();
                  })
                  .catch(function (err) {
                    if (err.response.status === 401) {
                      window.localStorage.removeItem("afs_token");
                      window.localStorage.removeItem("afs_user");
                      dispatch(userActions.setToken(null));
                      dispatch(userActions.setMe(null));
                      console.log(err.message);
                      reject();
                    } else if (err.response.status === 403) {
                      console.log(err.message);
                      reject();
                    } else {
                      console.log(err.message);
                    }
                  });
              }, 1000);
            }),
        }}
      />
    </div>
  );
};
