import React, { useState, useEffect, useContext } from "react";
import {
  FormControl,
  NativeSelect,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";
import { UserContext } from "../../providers/UserProvider";
import { NodeSelectorStyles } from "./NodeSelectorStyles";

export const NodeSelector = ({
  selectedNode,
  setSelectedNode,
  url,
  params,
  defaultSelected,
}) => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;
  const [nodes, setNodes] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = NodeSelectorStyles();

  const handleNodeChange = (event) => {
    setSelectedNode(nodes.find((node) => node.id === event.target.value));
  };

  useEffect(() => {
    const fetchNodes = async () => {
      setLoading(true);
      try {
        const res = await axios.get(url, {
          params: {
            name: params.name,
            tree_node: params.tree_node,
            parameter__code: params.parameter__code,
          },
          headers: {
            Authorization: `Bearer ${token.access}`,
          },
        });
        const { data } = res;

        const nodeIndex =
          defaultSelected === "end" ? res.data.length - 1 : defaultSelected;

        if (data.length > 0) {
          setSelectedNode(res.data[nodeIndex]);
          setNodes(res.data);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        // add more robust error handling here
        setError(err);
      }
    };

    fetchNodes();
  }, [
    setSelectedNode,
    params.name,
    params.tree_node,
    params.parameter__code,
    url,
    defaultSelected,
    dispatch,
    token,
  ]);

  return (
    <>
      {!loading ? (
        !error && nodes.length > 0 ? (
          <FormControl className={classes.formControl}>
            <NativeSelect
              value={selectedNode ? selectedNode.id : ""}
              onChange={handleNodeChange}
              inputProps={{
                name: "node",
                id: "node-select",
              }}
            >
              <option aria-label="None" value="" />
              {nodes?.map((value, index) => {
                return (
                  <option key={index} value={value.id}>
                    {value.name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        ) : (
          <Typography>Error: {JSON.stringify(error)}</Typography>
        )
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

NodeSelector.propTypes = {
  selectedNode: PropTypes.object,
  setSelectedNode: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  params: PropTypes.object,
  defaultSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
