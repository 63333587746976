import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { DateTime } from "luxon";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  FormControl,
  NativeSelect,
  Toolbar,
  IconButton,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
  Box,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { DatetimeSelector } from "../Utils/DateTimeSelector";
import { apiRoot } from "../../services/api";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { ConfigurationPlot } from "./ConfigurationPlot";
import { ConfigurationPlotTable } from "./ConfigurationPlotTable";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  forecastBar: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "575px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    justify: "right",
  },
  grow: {
    flexGrow: 1,
  },
  forecastApproval: {
    marginLeft: "auto",
  },
  forecastDetail: {
    marginLeft: "auto",
  },
  typography: {
    padding: theme.spacing(2),
  },
  popover: {
    padding: theme.spacing(2),
  },
  adjustTimeButton: {
    marginLeft: "auto",
  },
}));

function TabPanel({ children, value, index, ...other }) {
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabPanel}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const StationPlot = ({ feature }) => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;

  const [hasError, setErrors] = useState(false);
  const [loadingConfigs, setLoadingConfigs] = useState(true);
  const [loadingSelectedConfig, setLoadingSelectedConfig] = useState(true);
  const [plotConfigs, setPlotConfigs] = useState(null);
  const [selectedPlotConfig, setSelectedPlotConfig] = useState(null);
  const [plot, setPlot] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const [dateSelectorOpen, setDateSelectorOpen] = useState(false);
  const [startDatetime, setStartDatetime] = useState(
    DateTime.now().minus({ days: 30 })
  );
  const [endDatetime, setEndDatetime] = useState(null);

  const classes = useStyles();

  const handleDateSelectorOpen = () => {
    setDateSelectorOpen(true);
  };

  const handleDateSelectorClose = () => {
    setDateSelectorOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handlePlotChange = (event) => {
    setSelectedPlotConfig(
      plotConfigs.find((node) => node.id === event.target.value)
    );
  };

  const PlotSelection = () => {
    return (
      <AppBar className={classes.forecastBar} position="static" color="default">
        <Toolbar>
          <FormControl>
            <NativeSelect
              value={selectedPlotConfig ? selectedPlotConfig.id : ""}
              onChange={handlePlotChange}
              inputProps={{
                name: "plot",
                id: "plot-select",
              }}
            >
              <option aria-label="None" value="" />
              {plotConfigs.map((value, index) => {
                return (
                  <option key={index} value={value.id}>
                    {value.name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleDateSelectorOpen}
            color="primary"
            className={classes.adjustTimeButton}
          >
            <AccessTimeIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  };

  useEffect(() => {
    setErrors(null);

    const fetchPlotConfigs = async () => {
      if (feature) {
        setLoadingConfigs(true);
        try {
          const res = await axios.get(apiRoot + "/plots/", {
            params: {
              plot_group_code: "OBSERVATIONS",
              location: feature.id,
            },
            headers: {
              Authorization: `Bearer ${token.access}`,
            },
          });
          setPlotConfigs(res.data);
          setLoadingConfigs(false);
        } catch (err) {
          if (err.response.status === 401) {
            window.localStorage.removeItem("afs_token");
            window.localStorage.removeItem("afs_user");
            dispatch(userActions.setToken(null));
            dispatch(userActions.setMe(null));
            setErrors("Unauthorized");
            setLoadingConfigs(false);
          } else if (err.response.status === 403) {
            setErrors(
              "You do not have the appropriate permissions to view / use this resource."
            );
            setLoadingConfigs(false);
          } else {
            console.log(err);
            setErrors(err.message);
            setLoadingConfigs(false);
          }
        }
      }
    };

    setPlotConfigs(null);
    fetchPlotConfigs();
  }, [feature, dispatch, token]);

  useEffect(() => {
    if (plotConfigs) {
      setSelectedPlotConfig(plotConfigs[0]);
    }
  }, [plotConfigs]);

  useEffect(() => {
    setErrors(null);

    const fetchPlot = async () => {
      if (selectedPlotConfig) {
        setLoadingSelectedConfig(true);
        try {
          const res = await axios.post(
            apiRoot + "/plotly/",
            {
              plot_id: selectedPlotConfig.id,
              feature_id: feature.id,
              tzname: DateTime.now().zoneName,
            },
            {
              headers: {
                Authorization: `Bearer ${token.access}`,
              },
            }
          );
          setPlot(res.data);
          setLoadingSelectedConfig(false);
        } catch (err) {
          if (err.response.status === 401) {
            window.localStorage.removeItem("afs_token");
            window.localStorage.removeItem("afs_user");
            dispatch(userActions.setToken(null));
            dispatch(userActions.setMe(null));
            setErrors("Unauthorized");
            setLoadingSelectedConfig(false);
          } else if (err.response.status === 403) {
            setErrors(
              "You do not have the appropriate permissions to view / use this resource."
            );
            setLoadingSelectedConfig(false);
          } else {
            console.log(err);
            setErrors(err.message);
            setLoadingSelectedConfig(false);
          }
        }
      }
    };

    setPlot(null);
    fetchPlot();
  }, [selectedPlotConfig, feature, dispatch, token]);

  return (
    <>
      <>
        {!loadingConfigs ? (
          !hasError ? (
            plotConfigs.length > 0 ? (
              <>
                <PlotSelection />
                <AppBar position="static" color="default">
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="timeseries tabs"
                  >
                    <Tab label="Plot" {...a11yProps(0)} />
                    <Tab label="Table" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>

                {!loadingSelectedConfig ? (
                  <React.Fragment>
                    <TabPanel value={tabIndex} index={0}>
                      <ConfigurationPlot config={plot} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                      <ConfigurationPlotTable config={plot} />
                    </TabPanel>
                  </React.Fragment>
                ) : (
                  <CircularProgress />
                )}
              </>
            ) : (
              <Typography>
                No plots configured for {feature.properties.name} (
                {feature.properties.code}).
              </Typography>
            )
          ) : (
            <Typography>Error: {JSON.stringify(hasError)}</Typography>
          )
        ) : (
          <CircularProgress />
        )}
      </>

      <DatetimeSelector
        open={dateSelectorOpen}
        setOpen={setDateSelectorOpen}
        handleClose={handleDateSelectorClose}
        startDatetime={startDatetime}
        setStartDatetime={setStartDatetime}
        endDatetime={endDatetime}
        setEndDatetime={setEndDatetime}
      />
    </>
  );
};
