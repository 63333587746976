import "leaflet/dist/leaflet.css";
import "../../css/Map.css";
import React from "react";
import { GeoJSON } from "react-leaflet";

export const SubbasinGeoJSON = ({
  geoJsonRef,
  features,
  setSelectedFeature,
  selectable,
}) => {
  const basinStyle = (feature, layer) => {
    let defaultStyle = {
      fillColor: "blue",
      fillOpacity: 0.4,
      color: "grey",
      weight: 0.1,
      opacity: 1,
    };

    if (feature.properties.code === "BARGI") {
      defaultStyle.fillColor = "#CDAB65";
    } else if (feature.properties.code === "BILIGUNDULU") {
      defaultStyle.fillColor = "#D9B09E";
    } else if (feature.properties.code === "GANDHISAGAR") {
      defaultStyle.fillColor = "#E59800";
    } else if (feature.properties.code === "HATHINIKUND") {
      defaultStyle.fillColor = "#CD6799";
    } else if (feature.properties.code === "ISP") {
      defaultStyle.fillColor = "#D7D89F";
    } else if (feature.properties.code === "KABINI") {
      defaultStyle.fillColor = "#A83800";
    } else if (feature.properties.code === "KRS") {
      defaultStyle.fillColor = "#CECF65";
    } else if (feature.properties.code === "MUSIRI") {
      defaultStyle.fillColor = "blue";
    } else if (feature.properties.code === "PRATAPPUR") {
      defaultStyle.fillColor = "#FFEC69";
    } else if (feature.properties.code === "RAJGHAT") {
      defaultStyle.fillColor = "#FF6F68";
    } else if (feature.properties.code === "SSP") {
      defaultStyle.fillColor = "#E29174";
    } else if (feature.properties.code === "TAWA") {
      defaultStyle.fillColor = "#F4E800";
    }
    return defaultStyle;
  };

  const onEachSubbasin = (feature, layer) => {
    layer.on({
      click: () => {
        if (selectable) {
          setSelectedFeature(feature);
        }
      },
      mouseover: () => {
        layer.bindTooltip(feature.properties.name, { direction: "center" });
        layer.openTooltip();
        layer.setStyle(basinStyle);
      },
      mouseout: () => {
        layer.closeTooltip();
      },
    });
  };

  return (
    <GeoJSON
      ref={geoJsonRef}
      key={features}
      data={features}
      style={basinStyle}
      onEachFeature={onEachSubbasin}
    />
  );
};
