import React, { useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { ForecastStyles } from "../Forecasts/ForecastStyles";

export const StationMapListTabs = ({ mapComponent, listComponent }) => {
  const [tabValue, setTabValue] = useState("list");
  const classes = ForecastStyles();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <TabContext value={tabValue}>
        <Tabs
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs"
          value={tabValue}
        >
          <Tab label="List" value={"list"} />
          <Tab label="Map" value={"map"} />
        </Tabs>
        <TabPanel style={{ overflowY: "auto" }} value={"list"}>
          {listComponent}
        </TabPanel>
        <TabPanel
          style={{ padding: "0px" }}
          value={"map"}
          className={classes.tabPanel}
        >
          {mapComponent}
        </TabPanel>
      </TabContext>
    </>
  );
};
