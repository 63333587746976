import "leaflet/dist/leaflet.css";
import "../../css/Map.css";
import React from "react";
import { GeoJSON } from "react-leaflet";

export const BasinBoundaryGeoJSON = ({
  geoJsonRef,
  features,
  setSelectedFeature,
  selectable,
}) => {
  const style = {
    fillColor: "white",
    fillOpacity: 0.1,
    color: "black",
    weight: 2,
    opacity: 1,
  };
  const onEachBasin = (feature, layer) => {
    layer.on({
/*      click: () => {
        if (selectable) {
          setSelectedFeature(feature);
        }
      },*/
      mouseover: () => {
        layer.bindTooltip(feature.properties.name, { direction: "center" });
        layer.openTooltip();
      },
      mouseout: () => {
        layer.closeTooltip();
      },
    });
  };

  return (
    <GeoJSON
      ref={geoJsonRef}
      key={features}
      data={features}
      style={style}
      onEachFeature={onEachBasin}
    />
  );
};
