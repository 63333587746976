import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Plot from "react-plotly.js";
import { DateTime, Duration } from "luxon";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  plot: {
    width: "100%",
    height: "500px",
  },
  paper: {},
}));

export const TimeseriesPlot = (props) => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [layout, setLayout] = useState(null);
  const { tsList, forecastNode } = props;

  useEffect(() => {
    const shapes = (forecastNode) => {
      let yAxes = filteredYAxes(tsList);

      if (forecastNode) {
        let shapes = [];

        yAxes.forEach((ax) => {
          shapes.push({
            type: "line",
            xref: "x",
            yref: "paper",
            x0: DateTime.fromISO(forecastNode.forecast.valid_at).toMillis(),
            x1: DateTime.fromISO(forecastNode.forecast.valid_at).toMillis(),
            y0: ax.domain[0],
            y1: ax.domain[1],
            line: { color: "red" },
          });
        });
        return shapes;
      } else {
        return shapes;
      }
    };

    const formatLayout = (tsList) => {
      let yAxes = filteredYAxes(tsList);
      let layout = {
        autosize: true,
        showlegend: true,
        legend: {
          orientation: "h",
          x: 0.5,
          y: -0.2,
          xanchor: "center",
          // yanchor: 'top',
        },
        margin: {
          t: 40,
          r: 25,
          l: 50,
        },
        xaxis: {
          title: "Datetime (" + DateTime.now().toFormat("ZZZZ") + ")",
          showline: true,
          mirror: "allticks",
          showgrid: true,
          gridcolor: "lightgrey",
          // range:[],
          autorange: true,
          automargin: true,
        },
        shapes: shapes(forecastNode),
      };

      for (let i = 0; i < yAxes.length; i++) {
        layout["yaxis" + (i + 1)] = {
          title: yAxes[i].unitCode,
          domain: yAxes[i].domain,
          showline: true,
          mirror: "allticks",
          showgrid: true,
          gridcolor: "lightgrey",
          // range: [],
          autorange: true,
          automargin: true,
        };
      }

      return layout;
    };

    const formatData = (tsList) => {
      let tsDataList = [];
      let yAxes = filteredYAxes(tsList);

      tsList.forEach((ts) => {
        let meta = ts.metaInfo;
        let values = ts.data;
        let yAxisId = yAxes.findIndex((a) => {
          return a.unitCode === meta.parameter.unit.code;
        });
        yAxisId = "y" + (yAxisId + 1);

        let x = [];
        let y = [];
        let accm_y = [];
        let accm_val = 0;
        values.forEach((t) => {
          x.push(DateTime.fromISO(t.datetime).toISO());
          y.push(t.num_value);
          accm_val += t.num_value;
          accm_y.push(accm_val);
        });
        if (!meta.interval) {
          tsDataList.push({
            x: x,
            y: y,
            yaxis: yAxisId,
            type: "scatter",
            mode: "markers",
            name: meta.parameter.code,
          });
        } else if (meta.parameter.statistic_type.code === "INST") {
          tsDataList.push({
            x: x,
            y: y,
            yaxis: yAxisId,
            type: "scatter",
            mode: "markers+lines",
            name: meta.parameter.code,
          });
        } else if (meta.parameter.statistic_type.code === "ACCM") {
          tsDataList.push({
            x: x,
            y: y,
            yaxis: yAxisId,
            type: "bar",
            name: meta.parameter.code,
            width: Duration.fromISO(meta.interval).toMillis() * 1,
            offset: Duration.fromISO(meta.interval).toMillis() * -1,
          });
          tsDataList.push({
            x: x,
            y: accm_y,
            yaxis: yAxisId,
            type: "scatter",
            mode: "lines",
            name: "ACCM " + meta.parameter.code,
            visible: "legendonly",
          });
        } else {
          tsDataList.push({
            x: x,
            y: y,
            yaxis: yAxisId,
            type: "scatter",
            mode: "lines",
            name: meta.code,
          });
        }
      });
      return tsDataList;
    };

    const filteredYAxes = (tsList) => {
      tsList.map((ts) => {
        return ts.metaInfo.parameter.unit.code;
      });
      let startYDomain = 0.0;
      let endYDomain = 1.0;
      let space = 0.05;
      /*let orderedYAxes = [
        {
          unitCode: "CFS",
          relativeHeight: 1,
        },
        {
          unitCode: "ACFT",
          relativeHeight: 1.0,
        },
        {
          unitCode: "FT",
          relativeHeight: 1,
        },
        {
          unitCode: "IN",
          relativeHeight: 0.5,
        },
        {
          unitCode: "DEGF",
          relativeHeight: 0.5,
        },
        {
          unitCode: "V",
          relativeHeight: 0.5,
        },
        {
          unitCode: "PCT",
          relativeHeight: 0.5,
        },
        {
          unitCode: "NONE",
          relativeHeight: 0.5,
        },
        {
          unitCode: "MM",
          relativeHeight: 0.5,
        },
      ];
      let filteredAxes = orderedYAxes.filter((a) => {
        return units.indexOf(a.unitCode) > -1;
      });*/
      let filteredAxes = tsList.map((ts) => {
        return {
          unitCode: ts.metaInfo.parameter.unit.code,
          relativeHeight: 1,
        };
      });
      let totalWeights = filteredAxes
        .map((a) => a.relativeHeight)
        .reduce((acc, cur) => acc + cur);
      let availableHeight =
        endYDomain - startYDomain - space * (filteredAxes.length - 1);
      let start = startYDomain;
      let updatedAxes = filteredAxes.map((v, i) => {
        v.domain = [
          start,
          start + availableHeight * (v.relativeHeight / totalWeights),
        ];
        start =
          start + availableHeight * (v.relativeHeight / totalWeights) + space;
        return v;
      });

      return updatedAxes;
    };
    if (tsList.length > 0) {
      setData(formatData(tsList));
      setLayout(formatLayout(tsList));
    }
  }, [tsList, forecastNode]);

  return (
    <Paper>
      {data && layout ? (
        <Plot
          data={data}
          layout={layout}
          useResizeHandler={true}
          config={{ displaylogo: false }}
          className={classes.plot}
        />
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
};
