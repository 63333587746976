import React from "react";
import {
  List,
  ListItem,
  CircularProgress,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useGetStationList } from "../../hooks/useGetStationList";

export const ForecastStationList = ({ handleClick, selectedBasinNode }) => {
  const { stationList, loadingList, error } = useGetStationList();

  const filterForecastStations = (list) => {
    const filtPointFeats = list.filter(
      (feat) => feat.properties.parent === selectedBasinNode.properties.basinId
    );

    return filtPointFeats;
  };

  return (
    <>
      {loadingList && <CircularProgress />}
      <List dense>
        {stationList && selectedBasinNode && !loadingList && !error
          ? filterForecastStations(stationList).map((station) => {
              return (
                <ListItem
                  disableGutters
                  key={station.id}
                  onClick={() => handleClick(station)}
                  button
                >
                  <ListItemIcon>
                    <LocationOnIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={station.properties.code}
                    secondary={station.properties.name}
                  />
                </ListItem>
              );
            })
          : error}
      </List>
    </>
  );
};
