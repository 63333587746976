import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";

export const ConfigurationPlotTable = ({ config }) => {
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState(null);

  const getTitle = (axis) => {
    if ("title" in axis) {
      if (typeof axis.title === "string") {
        return axis.title;
      } else {
        return axis.title.text;
      }
    } else {
      return "Missing";
    }
  };

  useEffect(() => {
    if (config) {
      let plotLayout = config.layout;
      let plotData = config.data;
      let newColumns = [
        {
          title: getTitle(plotLayout.xaxis),
          field: "datetime",
          type: "datetime",
        },
      ];

      let traceData = [];
      let dtArr = [];
      plotData.forEach((trace) => {
        newColumns.push({
          title: trace.name,
          field: trace.name,
        });
        console.log(trace.name, trace.x.length);
        dtArr = dtArr.concat(trace.x);
      });
      let dtArrNoDups = [...new Set([...dtArr])];

      dtArrNoDups.forEach((dt) => {
        let obj = {};
        obj["datetime"] = dt;
        plotData.forEach((trace) => {
          obj[trace.name] = trace.y[trace.x.indexOf(dt)];
        });
        traceData.push(obj);
      });
      setColumns(newColumns);
      setData(traceData);
    }
  }, [config]);

  return (
    <Paper>
      {data && columns ? (
        <MaterialTable
          columns={columns}
          data={data}
          title={null}
          options={{
            exportButton: true,
            exportAllData: true,
            exportFileName: getTitle(config.layout),
          }}
        />
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
};
