import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Plot from "react-plotly.js";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  plot: {
    width: "100%",
    height: "100%",
  },
  paper: {
    width: "100%",
    height: "100%",
  },
}));

export const ConfigurationPlot = ({ config }) => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [layout, setLayout] = useState(null);

  useEffect(() => {
    if (config) {
      setData(config.data);
      setLayout(config.layout);
    }
  }, [config]);

  return (
    <Paper className={classes.paper}>
      {data && layout ? (
        <Plot
          data={data}
          layout={layout}
          useResizeHandler={true}
          config={{ displaylogo: false }}
          className={classes.plot}
        />
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
};
