import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { DateTime } from "luxon";
import {
  AppBar,
  FormControl,
  NativeSelect,
  Toolbar,
  Grid,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { apiRoot } from "../../services/api";
import { UserContext } from "../../providers/UserProvider";
import { userActions } from "../../reducers/userReducer.js";
import { ConfigurationPlot } from "../Plotting/ConfigurationPlot";

const useStyles = makeStyles((theme) => ({
  plotBar: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  typography: {
    padding: theme.spacing(2),
  },
  plotArea: {
    height: `calc(100vh - 260px)`,
    width: "inherit",
  },
}));

export const ForecastPlot = ({ feature, forecast, scenario }) => {
  const { state, dispatch } = useContext(UserContext);
  const { token } = state.user;
  const classes = useStyles();

  const [hasError, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);
  const [plotConfigs, setPlotConfigs] = useState(null);
  const [selectedPlotConfig, setSelectedPlotConfig] = useState(null);
  const [plot, setPlot] = useState(null);

  const handlePlotChange = (event) => {
    setSelectedPlotConfig(
      plotConfigs.find((node) => node.id === event.target.value)
    );
  };

  const PlotSelection = () => {
    return (
      <AppBar position={"static"} color={"default"}>
        <Toolbar>
          <Typography>Viewing: </Typography>
          <FormControl>
            <NativeSelect
              value={selectedPlotConfig ? selectedPlotConfig.id : ""}
              onChange={handlePlotChange}
              inputProps={{
                name: "plot",
                id: "plot-select",
              }}
            >
              <option aria-label="None" value="" />
              {plotConfigs?.map((value, index) => {
                return (
                  <option key={index} value={value.id}>
                    {value.name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </Toolbar>
      </AppBar>
    );
  };

  useEffect(() => {
    setErrors(null);

    const fetchPlotConfigs = async () => {
      try {
        const res = await axios.get(apiRoot + "/plots/", {
          params: {
            plot_group_code: scenario.name,
            location: feature.id,
          },
          headers: {
            Authorization: `Bearer ${token.access}`,
          },
        });
        const { data } = res;

        setPlotConfigs(data);
        data.length > 0 && setSelectedPlotConfig(data[0]);
        setLoading(false);
      } catch (err) {
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setErrors(err.message);
          setLoading(false);
        }
      }
    };

    if (feature && scenario) {
      setLoading(true);
      setPlotConfigs(null);
      setSelectedPlotConfig(null);
      fetchPlotConfigs();
    }
  }, [scenario, feature, dispatch, token]);

  useEffect(() => {
    setErrors(null);

    const fetchPlot = async () => {
      try {
        const res = await axios.post(
          apiRoot + "/plotly/",
          {
            plot_id: selectedPlotConfig.id,
            feature_id: feature.id,
            forecast_id: forecast.id,
            tzname: DateTime.now().zoneName,
            start_dt: DateTime.fromISO(forecast.valid_at).plus({ days: -3 }),
            end_dt: DateTime.fromISO(forecast.valid_at).plus({ days: 180 }),
          },
          {
            headers: {
              Authorization: `Bearer ${token.access}`,
            },
          }
        );
        const { data } = res;
        setPlot(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          window.localStorage.removeItem("afs_token");
          window.localStorage.removeItem("afs_user");
          dispatch(userActions.setToken(null));
          dispatch(userActions.setMe(null));
          setErrors("Unauthorized");
          setLoading(false);
        } else if (err.response.status === 403) {
          setErrors(
            "You do not have the appropriate permissions to view / use this resource."
          );
          setLoading(false);
        } else {
          console.log(err);
          setErrors(err.message);
          setLoading(false);
        }
      }
    };
    if (selectedPlotConfig) {
      setLoading(true);
      setPlot(null);
      fetchPlot();
    }
    // eslint-disable-next-line
  }, [selectedPlotConfig, forecast, dispatch, token]);

  return (
    <Grid className={classes.plotArea}>
      {!loading ? (
        !hasError ? (
          <>
            <PlotSelection />
            {plot ? (
              <ConfigurationPlot
                config={plot}
                plotConfig={selectedPlotConfig}
              />
            ) : (
              <Typography>
                No plots configured for {feature.properties.name} (
                {feature.properties.code}).
              </Typography>
            )}
          </>
        ) : (
          <Typography>Error: {JSON.stringify(hasError)}</Typography>
        )
      ) : (
        <CircularProgress />
      )}
    </Grid>
  );
};
