import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import { selectIcon, parseDateTime, StyledTreeItem } from "./TreeHelpers";

const useStyles = makeStyles((theme) => ({
  tree: {
    margin: theme.spacing(1),
    overflow: "auto",
    height: "600px",
  },
}));

const getTreeItemsFromData = (treeItems) => {
  return treeItems.map((treeItemData) => {
    let children = undefined;
    if (treeItemData.children && treeItemData.children.length > 0) {
      children = getTreeItemsFromData(treeItemData.children);
    }

    return (
      <StyledTreeItem
        key={treeItemData.id}
        nodeId={treeItemData.id}
        labelText={parseDateTime(treeItemData.name)}
        labelIcon={selectIcon(treeItemData)}
        color="#1a73e8"
        bgColor="#e8f0fe"
        children={children}
      />
    );
  });
};

export const DataTreeView = ({
  treeItems,
  selectedNodeId,
  setSelectedNodeId,
  expanded,
  setExpanded,
  hasError,
}) => {
  const classes = useStyles();

  return !hasError ? (
    <TreeView
      className={classes.tree}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      multiSelect={false}
      onNodeSelect={(e, v) => {
        setSelectedNodeId(v);
      }}
      onNodeToggle={(e, v) => {
        setExpanded(v);
      }}
      expanded={expanded}
      selected={selectedNodeId}
    >
      {getTreeItemsFromData(treeItems)}
    </TreeView>
  ) : (
    <Typography>Error: {JSON.stringify(hasError)}</Typography>
  );
};
