import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InfoIcon from "@material-ui/icons/Info";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  updatedDate: {
    marginLeft: "auto",
  },
}));

export const StationDetailList = ({ list }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main info folder">
        {list.map((s) => (
          <ListItem alignItems="flex-start" button>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText
              primary={<Typography>{s.name}</Typography>}
              secondary={
                <>
                  <Divider />
                  <Typography variant={"body2"}>
                    {s.text
                      .split("\n")
                      .map((text, i) => (i ? [<br />, text] : text))}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};
