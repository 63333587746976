import React, { useContext, useState } from "react";
import { Route, NavLink, Switch, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  MenuItem,
  Menu,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import TimelineIcon from "@material-ui/icons/Timeline";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MapIcon from "@material-ui/icons/Map";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import HomeIcon from "@material-ui/icons/Home";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useUsers } from "./hooks/userAPI";
import { adminUrl } from "./services/api";
import { UserContext } from "./providers/UserProvider";
import { TimeseriesList } from "./components/Timeseries/TimeseriesList.jsx";
import { TimeseriesDetailRoute } from "./components/Timeseries/TimeseriesDetailRoute.jsx";
import { Forecasts } from "./components/Forecasts/Forecasts.jsx";
import { Stations } from "./components/Stations/Stations.jsx";
import { Browser } from "./components/Timeseries/Browser.jsx";
import { PrecipTilesContainer } from "./components/GriddedData/PrecipTilesContainer";
import logo from "./images/EHP-logo.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
    color: "white",
    marginLeft: "2%",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#8cb3d9",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#8cb3d9",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    width: "100%",
    overflowY: "hidden",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    padding: theme.spacing(1),
    marginLeft: 0,
    flexGrow: 1,
    width: "100%",
    overflowY: "hidden",
  },
  user: {
    padding: theme.spacing(1),
  },
}));

const App = (props) => {
  const { window } = props;
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(true);
  const { logout } = useUsers();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const { state } = useContext(UserContext);
  const { token, me } = state.user;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleLogout = () => {
    logout(props);
    handleClose();
  };

  const mainContent = (
    <Switch>
      <Route exact path="/" component={Stations} />
      <Route exact path="/forecasts" component={Forecasts} />

      <Route path="/browser" component={Browser} />
      <Route path="/gridded_data" component={PrecipTilesContainer} />
      <Route path="/timeseries/:sourceId?" component={TimeseriesList} />
      <Route
        path="/timeseries_detail/:timeseriesId?"
        component={TimeseriesDetailRoute}
      />
    </Switch>
  );

  const location = useLocation();

  const CustomListItem = ({ to, primary, secondary = "", icon }) => {
    return (
      <ListItem
        button
        component={NavLink}
        to={to}
        selected={to === location.pathname}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    );
  };

  const drawerContent = (
    <div>
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon titleAccess="Close Sidebar Navigation" />
        </IconButton>
      </div>
      <Divider />
      <List>
        <CustomListItem to="/" primary="Home" icon={<HomeIcon />} />

        <CustomListItem
          to="/forecasts"
          primary="Forecasts"
          titleAccess="Forecasts Icon Link"
          icon={<MapIcon />}
        />

        <CustomListItem
          to="/browser"
          primary="Timeseries"
          icon={<TimelineIcon />}
        />
        <CustomListItem
          to="/gridded_data"
          primary="Gridded Data"
          icon={<ViewComfyIcon />}
        />

        <ListItem button component="a" target="_blank" href={adminUrl}>
          <ListItemIcon>
            <SupervisorAccountIcon titleAccess="Admin Console Icon Link" />
          </ListItemIcon>
          <ListItemText primary="Admin Console" />
        </ListItem>

        <ListItem button component="a" target="_blank">
          <ListItemIcon>
            <AccountTreeIcon titleAccess="Job Manager Icon Link" />
          </ListItemIcon>
          <ListItemText primary="Job Manager" secondary="(Inactive)" />
        </ListItem>
      </List>
    </div>
  );

  const appBarContent = (
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerOpen}
        className={openDrawer ? classes.hide : classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <img
        src={logo}
        alt="Logo for: Central Water Commission, Government of India Department of Water Resources"
        height={64}
      />
      <Typography variant="h6" className={classes.title} noWrap>
        EHP India
      </Typography>
      {token && (
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
            {me && (
              <Typography className={classes.user}>{me.first_name}</Typography>
            )}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openMenu}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      )}
    </Toolbar>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={openDrawer ? classes.appBarShift : classes.appBar}
      >
        {appBarContent}
      </AppBar>
      <nav className={classes.drawer} aria-label="navigation menu">
        <Drawer
          container={container}
          variant="persistent"
          anchor={"left"}
          open={openDrawer}
          onClose={handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawerContent}
        </Drawer>
      </nav>
      <main className={openDrawer ? classes.contentShift : classes.content}>
        <div className={classes.toolbar} />
        {mainContent}
      </main>
    </div>
  );
};

export default App;
