import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import { StationPlot } from "../Plotting/StationPlot";
import { StationOperations } from "./StationOperations";
import { StationInformation } from "./StationInformation";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  tabPanel: {
    padding: theme.spacing(1),
    // maxHeight: 750,
    overflow: "auto",
  },
  locationHeader: {
    flexGrow: 1,
  },
}));

function TabPanel({ children, value, index, ...other }) {
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabPanel}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const StationDetail = ({ feature }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography className={classes.locationHeader} variant={"h6"}>
            {feature.properties.name} ({feature.properties.code})
          </Typography>
        </Toolbar>
      </AppBar>

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Timeseries Data" {...a11yProps(0)} />
          <Tab label="Operations Updates" {...a11yProps(1)} />
          <Tab label="Station Information" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <StationPlot feature={feature} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <StationOperations feature={feature} />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <StationInformation feature={feature} />
      </TabPanel>
    </div>
  );
};
