import React, { useReducer } from "react";
import useCombinedReducers from "use-combined-reducers";
import { userReducer, userInitialState } from "../reducers/userReducer";

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useCombinedReducers({
    user: useReducer(userReducer, userInitialState),
  });
  return (
    <UserContext.Provider value={{ dispatch, state }}>
      {children}
    </UserContext.Provider>
  );
};
